import React, { Component } from "react";
import "./edit_node.scss";
import EditPopup from "./edit_popup/question_edit_popup";

export default class edit_node extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.ref2 = React.createRef();
    this.state = {
      canRichText: true,
      showQuestionEditPopup: false,
    };
  }

  onChange() {
    var html = "";
    if (this.props.canRichText) {
      html = this.ref.current.innerHTML;
    } else {
      html = this.ref.current.innerText;
    }
    // html = html.replace(/[\n]+/g, "")  // 先可以有换行
    if (this.props.onChange && html !== this.lastHtml) {
      this.props.onChange({ value: html });
    }
    this.lastHtml = html;
  }

  componentDidMount() {
    let reg = /<[^<>]+>/g;
    if (reg.test(this.props.value)) {
      this.setState({
        canRichText: true,
      });
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.canRichText) {
      return nextProps.value !== this.ref.current.innerHTML;
    } else {
      return nextProps.value !== this.ref.current.innerText;
    }
  }

  componentDidUpdate(preProps, preState) {
    if (this.props.canRichText || this.state.canRichText) {
      if (this.props.value !== this.ref.current.innerHTML) {
        this.ref.current.innerHTML = this.props.value;
        this.setState({ canRichText: true });
      }
    } else {
      if (this.props.value !== this.ref.current.innerText) {
        this.ref.current.innerText = this.props.value;
      }
    }
  }

  // 点击编辑题目 显示popup并传值
  editQuestionTitle() {
    if (this.ref2.current) {
      this.ref2.current.open();
    }
  }
  handleQuestionEditPopupStatus(v) {
    this.props.onChange({ value: v.name });
  }

  render() {
    const { className, value } = this.props;
    return (
      <div className="edit-question-title-wrap">
        <p
          contentEditable="true"
          className={className}
          dangerouslySetInnerHTML={{ __html: value }}
          ref={this.ref}
          onInput={this.onChange.bind(this)}
          onBlur={this.onChange.bind(this)}
          placeholder={this.props.placeholder}
        ></p>
        {this.props.isEdit ? (
          <div
            className="edit-question-btn"
            onClick={this.editQuestionTitle.bind(this)}
          >
            富文本编辑
          </div>
        ) : (
          ""
        )}
        <EditPopup
          ref={this.ref2}
          showQuestionEditPopup={this.state.showQuestionEditPopup}
          handleQuestionEditPopupStatus={this.handleQuestionEditPopupStatus.bind(
            this
          )}
          question_name={value}
        ></EditPopup>
      </div>
    );
  }
}
